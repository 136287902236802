    function updateCtaBtn(cName,cUrl){
        var uparams = new URLSearchParams(window.location.search.replaceAll(/#/g, ""));
        var pparams = '';
        for(let pair of uparams.entries()){
           pparams += (pparams=='')?'':'&';
           pparams += pair[0]+"="+encodeURIComponent(pair[1]);
        }     

        var sourceVals = getSourceValues(uparams);
        pparams += sourceVals!=''?((pparams=='')?'':'&')+sourceVals:'';
        
        // Select all buttons using the class name
        var buttons = document.querySelectorAll(cName);
        var checkout_url = cUrl;
        // Change the href value for each button
        for (var i = 0; i < buttons.length; i++) {
          buttons[i].href = checkout_url+"?"+pparams;
        }
      }

    //this is for CTA button in COC Builder
    function updateCtaBtnCoc(cName,cUrl){
        var uparams = new URLSearchParams(window.location.search.replaceAll(/#/g, ""));
        var pparams = '';
        for(let pair of uparams.entries()){
           pparams += (pparams=='')?'':'&';
           pparams += pair[0]+"="+encodeURIComponent(pair[1]);
        }
      
        // Select all buttons using the class name
        var buttons = document.querySelectorAll(cName);
        var checkout_url = cUrl;
        // Change the href value for each button
        for (var i = 0; i < buttons.length; i++) {
          buttons[i].href = checkout_url+"?"+pparams;
        }
      
      }

  function updateAtcBtn(class_name,checkout_url){
      var selected_id = document.querySelectorAll('.item_selected')[0].id;
      var uparams = new URLSearchParams(window.location.search.replace(/#/g, ""));
      var pparams = '';
      for(let pair of uparams.entries()){
         pparams += (pparams=='')?'':'&';
         pparams += pair[0]+"="+encodeURIComponent(pair[1]);
      }
      pparams += '&item_selected=' + selected_id;
      var sourceVals = getSourceValues(uparams);
      pparams += sourceVals!=''?((pparams=='')?'':'&')+sourceVals:'';
    
      // Select all buttons using the class name
      var buttons = document.querySelectorAll(class_name);
      // Change the href value for each button
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].href = checkout_url+"?"+pparams;
      }
  }

  function updateCtaBtnWithEmail(cUrl,email){
        var uparams = new URLSearchParams(window.location.search.replaceAll(/#/g, ""));
        var pparams = '';
        for(let pair of uparams.entries()){
           pparams += (pparams=='')?'':'&';
           pparams += pair[0]+"="+encodeURIComponent(pair[1]);
        }
    
        var sourceVals = getSourceValues(uparams);
        pparams += sourceVals!=''?((pparams=='')?'':'&')+sourceVals:'';
    
        window.location.href = cUrl+"?"+pparams+"&adv_sub4="+email;
  }

  function nullCheck(txt){
    return (txt!=null && txt!=undefined)?txt:'';
  }

  function getBrowserCookie(name) {
       var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match){ 
            return (match[2]==undefined || match[2]==null)?'':match[2];
        }else return '';
 	}

  function getSourceValues(uparams){
      var pparams='';
        var c5 = uparams.get('c5');
        var c3 = getBrowserCookie('_cerebro_tid');
        var affId = uparams.get('affId');

        if( c5 == null || c5 == undefined ){
          var aff_id = nullCheck(uparams.get('aff_id'));
          var offer_id = nullCheck(uparams.get('offer_id'));
          var aff_sub = nullCheck(uparams.get('aff_sub'));
          var aff_sub2 = nullCheck(uparams.get('aff_sub2'));
          
          var c1 = aff_id+'__'+aff_sub+'__'+aff_sub2;
          var c2 = aff_id;
          var c4 = offer_id;
          pparams += ((pparams=='')?'':'&')+'c1='+c1+'&c2='+c2+'&c4='+c4;
        }
        pparams += ((pparams=='')?'':'&')+'c3='+c3;
        if(affId===null||affId===undefined) pparams += ((pparams=='')?'':'&')+'affId=E29F088C';
    return pparams;
  }